<template>
  <div>
    <div v-if="submitted">
      <h2 class="mb-8 text-center text-primary text-h1 mt-60px">
        Thank You for your Inquiry!
      </h2>
      <p class="text-center">
        Someone from our business development team will be contacting you
        shortly.
      </p>
      <p class="mb-3 text-center">
        Don't forget to follow us on Instagram for our latest updates.
      </p>
      <div class="w-full px-3 mb-6 field">
        <button type="button" class="w-full button is-success submit-form" @click="resetForm">
          Reset Form
        </button>
      </div>
    </div>
    <form @submit.prevent="submit" v-if="!submitted" class="text-left" autocomplete="off">
      <div class="w-full px-3 mb-6 field">
        <base-input
            autocomplete="off"
            :icon="`fa-dot-circle`"
            tabindex="4"
            :input-class="'text-black'"
            :placeholder="'Company'"
            name="company"
            v-model="formData.company"
            @blur="
              $v.formData.company ? $v.formData.company.$touch() : () => {}
            "
            type="text"
            :validations="
              $v.formData.company
                ? [
                    {
                      condition:
                        $v.formData.company.$error &&
                        !$v.formData.company.required,
                      text: validateMessage('required', { attr: 'Company' }),
                    },
                    {
                      condition: !$v.formData.company.minLength,
                      text: validateMessage('minLength', {
                        attr: 'Company',
                        min: 3,
                      }),
                    },
                  ]
                : []
            "
        >
          Company <span>{{ companyFieldRequired }}</span>
        </base-input>
      </div>
      <div class="w-full px-3 mb-6 field">
        <base-input
            :icon="`fa-dot-circle`"
            tabindex="2"
            :input-class="'text-black'"
            placeholder="First name"
            autocomplete="off"
            name="first-name"
            type="text"
            v-model="formData.first_name"
            @blur="$v.formData.first_name.$touch()"
            :validations="[
              {
                condition:
                  $v.formData.first_name.$error &&
                  !$v.formData.first_name.required,
                text: validateMessage('required', { attr: 'First name' }),
              },
              {
                condition: !$v.formData.first_name.minLength,
                text: validateMessage('minLength', {
                  attr: 'First name',
                  min: 2,
                }),
              },
            ]"
        >
          First name <span>*</span>
        </base-input>
      </div>
      <div class="w-full px-3 mb-6 field">
        <base-input
            :icon="`fa-dot-circle`"
            tabindex="3"
            :input-class="'text-black'"
            placeholder="Last name"
            autocomplete="off"
            name="last-name"
            type="text"
            v-model="formData.last_name"
            @blur="$v.formData.last_name.$touch()"
            :validations="[
              {
                condition:
                  $v.formData.last_name.$error &&
                  !$v.formData.last_name.required,
                text: validateMessage('required', { attr: 'Last name' }),
              },
              {
                condition: !$v.formData.last_name.minLength,
                text: validateMessage('minLength', {
                  attr: 'Last name',
                  min: 2,
                }),
              },
            ]"
        >
          Last name <span>*</span>
        </base-input>
      </div>
      <div class="w-full px-3 mb-6 field">
        <base-input
            :icon="`fa-phone`"
            tabindex="5"
            :input-class="'text-black'"
            :placeholder="'Phone'"
            autocomplete="off"
            name="phone"
            type="text"
            @blur="$v.formData.phone.$touch()"
            v-model="formData.phone"
            :validations="[
              {
                condition:
                  $v.formData.phone.$error && !$v.formData.phone.required,
                text: validateMessage('required', { attr: 'Phone' }),
              },
              {
                condition: !$v.formData.phone.minLength,
                text: validateMessage('minLength', { attr: 'Phone', min: 5 }),
              },
              {
                condition: !$v.formData.phone.isPhone,
                text: validateMessage('isPhone', { attr: 'Phone' }),
              },
            ]"
        >
          Phone <span>*</span>
        </base-input>
      </div>
      <div class="w-full px-3 mb-6 field">
        <base-input
            tabindex="6"
            :icon="`fa-envelope`"
            :input-class="'text-black'"
            :placeholder="'Email'"
            autocomplete="off"
            name="email"
            v-model="formData.email"
            @blur="$v.formData.email.$touch()"
            type="email"
            :validations="[
              {
                condition:
                  $v.formData.email.$error && !$v.formData.email.required,
                text: validateMessage('required', { attr: 'Email' }),
              },
              {
                condition: !$v.formData.email.email,
                text: validateMessage('email', { attr: 'Email' }),
              },
            ]"
        >
          Email <span>*</span>
        </base-input>
      </div>
<!--      <div class="w-full px-3 mb-6 field">
        <label for="map" class="block mb-2 font-bold label">Address *</label>
        <div class="form-control relative control has-icons-left">
          <vue-google-autocomplete id="map"
                                   classname="input w-full"
                                   placeholder="Start typing"
                                   v-on:placechanged="getAddressData"
                                   autocomplete="off"
          >
          </vue-google-autocomplete>
          <span class="icon is-small is-left">
                      <i :class="['fas', 'fa-home']"></i>
                    </span>
        </div>
        <ValidationMessages v-if="validateAutocomplete" :validations="validateAutocomplete"/>
      </div>-->

      <div class="w-full px-3 mb-6 field">
        <base-input
            tabindex="10"
            :input-class="'text-black'"
            :placeholder="'Address Line 1'"
            autocomplete="address-line1"
            name="address"
            v-model="formData.address"
            @blur="$v.formData.address.$touch()"
            type="text"
            :validations="[
              {
                condition:
                  $v.formData.address.$error && !$v.formData.address.required,
                text: validateMessage('required', { attr: 'Address line 1' }),
              },
              {
                condition: !$v.formData.address.minLength,
                text: validateMessage('minLength', {
                  attr: 'Address line 1',
                  min: 5,
                }),
              },
            ]"
        >
          Address Line 1 <span>*</span>
        </base-input>
      </div>
      <div class="w-full px-3 mb-6 field">
        <base-input
            tabindex="11"
            :input-class="'text-black'"
            :placeholder="'Address Line 2'"
            autocomplete="address-line2"
            name="address2"
            v-model="formData.address2"
            type="text"
        >
          Address Line 2
        </base-input>
      </div>
      <div class="w-full px-3 mb-6 field">
        <base-select
            tabindex="12"
            name="countries"
            :id="'countries'"
            :options="countryOptions"
            :selected="formData.country"
            v-model="formData.country"
            autocomplete="country"
        >
          Country <span>*</span>
        </base-select>
      </div>
      <div class="w-full px-3 mb-6 field">
        <base-select
            tabindex="13"
            class="col-xs-12 col-sm-6 mb25"
            name="state"
            :id="'state'"
            :options="
              formData.country === 'us' ? usaStateOptions : canadaStateOptions
            "
            :selected="formData.state"
            v-model="formData.state"
            autocomplete="country"
            placeholder="Please select a state or province..."
            :use-placeholder="true"
            :validations="[
              {
                condition:
                  $v.formData.state.$error && !$v.formData.state.required,
                text: validateMessage('required', { attr: 'State/Province' }),
              }
            ]"
        >
          State/Province <span>*</span>
        </base-select>
      </div>
      <div class="w-full px-3 mb-6 field">
        <base-input
            tabindex="14"
            :input-class="'text-black'"
            :placeholder="'City'"
            autocomplete="address-level2"
            name="city"
            v-model="formData.city"
            @blur="$v.formData.city.$touch()"
            type="text"
            :validations="[
              {
                condition:
                  $v.formData.city.$error && !$v.formData.city.required,
                text: validateMessage('required', { attr: 'City' }),
              },
              {
                condition: !$v.formData.city.minLength,
                text: validateMessage('minLength', { attr: 'City', min: 5 }),
              },
            ]"
        >
          City <span>*</span>
        </base-input>
      </div>
      <div class="w-full px-3 mb-6 field">
        <base-input
            tabindex="15"
            :input-class="'text-black'"
            :placeholder="'Postal/Zip Code'"
            autocomplete="postal-code"
            name="postal_code"
            v-model="formData.postal_code"
            @blur="$v.formData.postal_code.$touch()"
            type="text"
            :validations="[
              {
                condition:
                  $v.formData.postal_code.$error &&
                  !$v.formData.postal_code.required,
                text: validateMessage('required', { attr: 'Postal/Zip code' }),
              },
              {
                condition: !$v.formData.postal_code.minLength,
                text: validateMessage('minLength', {
                  attr: 'Postal/Zip code',
                  min: 3,
                }),
              },
            ]"
        >
          Postal/Zip Code <span>*</span>
        </base-input>
      </div>
      <div class="w-full px-3 mb-6 field"></div>
      <div class="w-full px-3 mb-6 field"></div>
      <div class="w-full px-3 mb-6 field">
        <button-outline class="w-full button is-success submit-form" :type="'submit'" tabindex="27">
          Submit
        </button-outline>
      </div>
    </form>
  </div>
</template>

<script>
import {required, minLength, email} from "vuelidate/lib/validators";
import BaseInput from "./BaseInput.vue";
import ButtonOutline from "./ButtonOutline.vue";
import BaseSelect from './BaseSelect.vue'
//import ValidationMessages from './ValidationMessages.vue'
import validateMessage from "../helpers/validateMessage.js";
//import ValidationMessages from "./ValidationMessages.vue";
import {validationMixin} from "vuelidate";
import {isPhone} from "@/helpers/validationRules";
import {httpClient} from "@/helpers/httpClient";
//import VueGoogleAutocomplete from "vue-google-autocomplete";

export default {
  name: "MainForm",
  components: {
    BaseInput,
    ButtonOutline,
    //VueGoogleAutocomplete,
    //BaseCheckbox,
    BaseSelect,
    //ValidationMessages
  },
  data() {
    return {
      submissionQueue: [],
      reseller_file: null,
      submitted: false,
      sourceDataOptions: [
        {label: "Salon Near Me", value: "salon"},
        {label: "A Friend", value: "friend"},
        {label: "Instagram", value: "instagram"},
        {label: "Facebook", value: "facebook"},
        {label: "Modern Salon", value: "modern-salon"},
        {label: "Salon Today", value: "salon-today"},
        {label: "Estetica", value: "estetica"},
        {label: "Beauty Launchpad", value: "beauty-launchpad"},
        {label: "Other", value: "other"},
      ],
      countries: {
        us: "United States",
        ca: "Canada",
      },
      usaStates: {
        AL: "Alabama",
        AK: "Alaska",
        AS: "American Samoa",
        AZ: "Arizona",
        AR: "Arkansas",
        CA: "California",
        CO: "Colorado",
        CT: "Connecticut",
        DE: "Delaware",
        DC: "District Of Columbia",
        FM: "Federated States Of Micronesia",
        FL: "Florida",
        GA: "Georgia",
        GU: "Guam",
        HI: "Hawaii",
        ID: "Idaho",
        IL: "Illinois",
        IN: "Indiana",
        IA: "Iowa",
        KS: "Kansas",
        KY: "Kentucky",
        LA: "Louisiana",
        ME: "Maine",
        MH: "Marshall Islands",
        MD: "Maryland",
        MA: "Massachusetts",
        MI: "Michigan",
        MN: "Minnesota",
        MS: "Mississippi",
        MO: "Missouri",
        MT: "Montana",
        NE: "Nebraska",
        NV: "Nevada",
        NH: "New Hampshire",
        NJ: "New Jersey",
        NM: "New Mexico",
        NY: "New York",
        NC: "North Carolina",
        ND: "North Dakota",
        MP: "Northern Mariana Islands",
        OH: "Ohio",
        OK: "Oklahoma",
        OR: "Oregon",
        PW: "Palau",
        PA: "Pennsylvania",
        PR: "Puerto Rico",
        RI: "Rhode Island",
        SC: "South Carolina",
        SD: "South Dakota",
        TN: "Tennessee",
        TX: "Texas",
        UT: "Utah",
        VT: "Vermont",
        VI: "Virgin Islands",
        VA: "Virginia",
        WA: "Washington",
        WV: "West Virginia",
        WI: "Wisconsin",
        WY: "Wyoming",
      },
      canadaStates: {
        AB: "Alberta",
        BC: "British Columbia",
        MB: "Manitoba",
        NB: "New Brunswick",
        NL: "Newfoundland and Labrador",
        NT: "Northwest Territories",
        NS: "Nova Scotia",
        NU: "Nunavut",
        ON: "Ontario",
        PE: "Prince Edward Island",
        QC: "Quebec",
        SK: "Saskatchewan",
        YT: "Yukon Territory",
      },
      sourceAddField: ["salon", "other"],
      sourceDataTitles: {
        salon: "Which Salon?",
        other: "How did you hear about us?",
      },
      formData: {
        event_source: 'EVENT_FORM,PRO35',
        open_active: true,
        stylist_type: 1,
        existing_customer: null,
        newsletter: 0,
        first_name: "",
        last_name: "",
        company: "",
        phone: "",
        email: "",
        address: "",
        address2: "",
        city: "",
        postal_code: "",
        country: "us",
        state: "",
        url: "",
        instagram: "",
        facebook: "",
        comments: "",
        source: "",
        source_data: "",
        job_title: "",
        product_lines: "",
        worked_in_salon: null,
        fte: null,
        in_business_since: null,
        salon_worked_at: "",
        reseller_number: "",
        reseller_certificate: "",
        license_number: "",
        issuing_state: "",
        issuing_country: ""
      },
    };
  },
  validations() {
    let validationsData = {
      formData: {
        first_name: {
          required,
          minLength: minLength(2),
        },
        last_name: {
          required,
          minLength: minLength(2),
        },
        phone: {
          required,
          minLength: minLength(5),
          isPhone,
        },
        email: {
          required,
          email,
          minLength: minLength(5),
        },
        address: {
          required,
          minLength: minLength(5),
        },
        city: {
          required,
          minLength: minLength(3),
        },
        state: {
          required
        },
        postal_code: {
          required,
          minLength: minLength(3),
        },
      },
    };

    if (this.formData.reseller_number !== "") {
      validationsData.formData.reseller_number = {
        minLength: minLength(3)
      }

      validationsData.formData.reseller_certificate = {
        required
      }
    }

    if (
        this.formData.stylist_type !== "" &&
        this.formData.stylist_type === "0"
    ) {
      validationsData.formData.company = {
        required,
        minLength: minLength(3),
      };

      validationsData.formData.job_title = {
        required,
        minLength: minLength(3),
      };
    }

    if (
        this.formData.stylist_type !== "" &&
        this.formData.stylist_type === "1"
    ) {
      validationsData.formData.worked_in_salon = {
        required,
      };
    }

    if (this.sourceAddField.includes(this.formData.source)) {
      validationsData.formData.source_data = {
        required,
        minLength: minLength(3),
      };
    }

    return validationsData;
  },
  created() {
  },
  beforeMount() {

  },
  mounted() {
    if (window) {
      const event = new CustomEvent("lbp/carry-us.mounted");
      window.dispatchEvent(event);

      window.setInterval(() => {
        this.submitQueued()
      }, 60 * 1000)

      if (window.location.origin.includes('sola')) {
        this.formData.event_source = 'SOLA2024,PRO35'
      }

      if (window.location.origin.includes('federico')) {
        this.formData.event_source = 'FedericoAlumni,PRO35'
      }
    }
  },
  computed: {
    validateAutocomplete() {
      return [
        {
          condition: !this.formData.address,
          text: this.validateMessage('address', {attr: 'Address'}),
        }
      ]
    },
    companyFieldRequired: function () {
      return this.formData.stylist_type === "0" ? "*" : "";
    },
    countryOptions: function () {
      return Object.keys(this.countries).map((el) => {
        return { label: this.countries[el], value: el };
      });
    },
    usaStateOptions: function () {
      return Object.keys(this.usaStates).map((el) => {
        return { label: this.usaStates[el], value: el };
      });
    },
    canadaStateOptions: function () {
      return Object.keys(this.canadaStates).map((el) => {
        return { label: this.canadaStates[el], value: el };
      });
    },
    usaStateOptionsFull: function () {
      return Object.keys(this.usaStates).map((el) => {
        return { label: this.usaStates[el], value: this.usaStates[el] };
      });
    },
    canadaStateOptionsFull: function () {
      return Object.keys(this.canadaStates).map((el) => {
        return { label: this.canadaStates[el], value: this.canadaStates[el] };
      });
    },
  },
  methods: {
    resetForm() {
      Object.assign(this.$data, this.$options.data.apply(this))
      console.log('resetting')
    },
    getAddressData: function (addressData) {
      this.formData.address = addressData.street_number + ' ' + addressData.route;
      this.formData.city = addressData.locality
      this.formData.postal_code = addressData.postal_code
      this.formData.state = addressData.administrative_area_level_1
      this.formData.country = Object.keys(this.countries).find(key => this.countries[key] === addressData.country)

      this.$nextTick(() => {
        this.$refs.addr2.$refs.address2.focus();
      })
    },
    submit: function () {
      this.formData.stylist_type = 1
      this.formData.existing_customer = 0

      let formData = Object.assign({}, this.formData);

      if (formData.country === "us") {
        formData["state-" + formData.country] = formData.state;
      }

      let bodyFormData = new FormData();

      for (let key in formData) {
        if (formData[key] === null || formData[key] === "") continue;
        bodyFormData.append(key, formData[key]);
      }

      if (this.$v.$invalid) {
        // Notification
        this.$v.$touch();
        this.scrollToError();
      } else {
        httpClient
            .post("https://data-bridge.luxbp.com/v1/lead", bodyFormData, {
              headers: {
                'Content-Type': 'multipart/form-data',
                'x-domain': 'randco-pro.myshopify.com'
              }
            })
            .then((response) => {
              // handle success
              if (response.data.status === true) {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                  event: "carry-us-submission",
                  category: "Form",
                  action: "Submit",
                  label: "CarryUsFormSubmission",
                });

                if (typeof window.ga === "function") {
                  window.ga("send", {
                    hitType: "event",
                    eventCategory: "Form",
                    eventAction: "Submit",
                    eventLabel: "CarryUsFormSubmission",
                  });
                }
                this.submitted = true;
                window.scrollTo(0, 0);
                window.setTimeout(() => {
                  if (this.submitted) {
                    this.resetForm()
                  }
                }, 60 * 1000)
              }
            })
            .catch((error) => {
              if (!navigator.onLine) {
                this.submissionQueue.push(this.formData)
                this.submitted = true;
                window.scrollTo(0, 0);
                return;
              }

              if (error.response && error.response.data && error.response.data.errors) {
                if (typeof error.response.data.errors === 'object') {
                  alert(Object.values(error.response.data.errors || {}).join('\r\n'));
                }
              } else {
                alert('There was an unknown error with your submission.')
              }
            });
      }
    },
    submitQueued() {
      for (const x of this.submissionQueue) {
        httpClient
            .post("https://data-bridge.luxbp.com/v1/lead", this.submissionQueue[x], {
              headers: {
                'Content-Type': 'multipart/form-data',
                'x-domain': 'randco-pro.myshopify.com'
              }
            })
            .then(() => {
              delete this.submissionQueue[x]
            })
            .catch((error) => {
              console.log(error.response.data)
            })
      }
    },
    scrollToError() {
      setTimeout(() => {
        let errors = document.querySelectorAll(".errorMessage");
        if (errors && errors.length) {
          let top =
              window.pageYOffset + errors[0].getBoundingClientRect().top - 300;
          window.scrollTo({
            top: top,
            left: 0,
            behavior: "smooth",
          });
        }
      }, 100);
    },
  },
  mixins: [validateMessage, validationMixin],
};
</script>

<style lang="css">
/*
label {
  font-size: 16px;
  font-family: 'agenda-bold';
  letter-spacing: 0.1em;
  text-transform: uppercase;
}
label span {
  color: #FD818D;
}
@media screen and (min-width: 768px) {
  input {
    padding: 1.25rem;
  }
}
 */
img {
  border-width: 0;
  border-style: none;
}
</style>

<style lang="css" scoped>
/* @import "../assets/css/output.css"; */

form {
  position: relative;
}

@media screen and (min-width: 768px) {
  .md\:w-1\/2 {
    margin-top: 14px !important;
  }
}

.content-title {
  text-align: left;
  font-size: 30px !important;
  position: relative;
  margin-top: 2rem;
  font-family: "agenda-bold", sans-serif;
  text-transform: uppercase;
  margin-bottom: 1rem;
}

.content-title:first-child {
  margin-top: 0;
}

.content-title:before {
  display: none;
  content: "";
  position: absolute;
  width: 25px;
  height: 2px;
  bottom: 0;
  left: 0;
  border: solid 1px;
}
</style>
